@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
* {
  font-family: "Orbitron", sans-serif;
  text-decoration: none;
  margin: 0;
  button,
  a {
    &:is(:focus, :focus-visible, :focus-within) {
      outline: none;
      box-shadow: none;
      -webkit-appearance: none;
    }
  }
}

* {
  --white: #fff;
  --bgBaseColor: #1e212a;
  --colorGray1: #838da4;
  --colorGray2: #262a33;
  --colorGray260: #2c3039e8;
  --dark1: #1f222a;
  --font14: 14px;
  --font16: 16px;
  --font18: 18px;
  --font22: 22px;
  --font20: 20px;
  --font30: 30px;
  --font50: 50px;
  --font70: 70px;
}

@media (max-width: 991px) {
  * {
    --font14: 13px;
    --font16: 14px;
    --font18: 16px;
    --font22: 20px;
    --font20: 18px;
    --font30: 26px;
    --font50: 40px;
    --font70: 50px;
  }
}
@media (max-width: 767px) {
  * {
    --font14: 12px;
    --font16: 12px;
    --font18: 14px;
    --font22: 18px;
    --font20: 16px;
    --font30: 22px;
    --font50: 34px;
    --font70: 40px;
  }
}

body {
  background: var(--bgBaseColor) !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}