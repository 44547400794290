.container {
  max-width: 1293px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.customButton {
  background: linear-gradient(180deg, #f77c2e 0%, #e64b0f 100%);
  border-radius: 30px;
  padding: 14px 38px;
  color: var(--white);
  font-size: var(--font18);
  font-weight: 400;
  border: 0;
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    background: linear-gradient(180deg, #e64b0f 0%, #f77c2e 100%);
  }
}
.textGredOrange {
  background: linear-gradient(180deg, #f77c2e 0%, #e64b0f 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.textGredGray {
  background: linear-gradient(180deg, #fafaf8 0%, #c6c4c7 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.headingText {
  color: var(--white);
  font-size: var(--font50);
  font-weight: 600;
  margin-bottom: 20px;
}
.subHeadingText {
  color: var(--colorGray1);
  font-size: var(--font16);
  font-weight: 400;
  margin-bottom: 50px;
  line-height: 25px;
}

.drawerStyle {
  overflow: hidden;
}

//DrawerCss//
#EZDrawer__container,
#EZDrawer__container,
.EZDrawer__container {
  background-color: var(--colorGray2) !important;
  @media (max-width: 767px) {
    max-width: 320px !important;
  }
}
.EZDrawer__overlay {
  opacity: 0.25 !important;
}
.EZDrawer {
  position: absolute;
  .EZDrawer__container {
    transition: 0.4s linear !important;
  }
}
.drawerCloseIcon {
  padding: 20px;
  background-color: transparent;
  border: 0;
  transition: 0.3s all;
  text-align: right;
  cursor: pointer;
  // position: absolute;
  // right: 5px;
  // top: 0px;
  // z-index: 9;
  &:hover {
    svg,
    path,
    g {
      fill: #f77c2e;
    }
  }
}

.commonDrawer {
  display: flex;
  flex-direction: column;
  .drawerCloseIcon {
    // position: relative !important;
  }
}

.wallet-adapter-modal-wrapper {
  // background-color: #54585f !important;
  background-color: #0f151d70 !important;
  max-width: 560px !important;
  width: 100% !important ;
  border-radius: 35px !important;
  button.wallet-adapter-modal-button-close {
    background: transparent;
    padding: 10px;
    color: #fff;
    svg {
      fill: #fff;
      width: 22px;
      height: 22px;
    }
  }
  .wallet-adapter-modal-title {
    background: linear-gradient(180deg, #f77c2e 0%, #e64b0f 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    max-width: 430px !important;
    width: 100% !important;
    font-size: var(--font30);
    padding-bottom: 40px;
    line-height: 150%;
  }
}
.wallet-adapter-modal-list {
  padding: 20px !important;
  margin-bottom: 40px !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .wallet-adapter-button {
    border-radius: 30px !important;
    color: var(--black);
    font-weight: 700 !important;
    border-radius: 0;
    // background: rgb(250, 250, 248);
    // background: linear-gradient(
    //   90deg,
    //   rgba(250, 250, 248, 1) 0%,
    //   rgba(198, 196, 199, 1) 100%
    // ) !important;
    background: rgb(250, 250, 248) !important;
    background: linear-gradient(
      90deg,
      rgba(250, 250, 248, 1) 0%,
      rgba(198, 196, 199, 1) 37%
    ) !important;
    font-size: var(--font20) !important;
  }
}

.customModal {
  .modal-dialog {
    max-width: 892px;
    .modal-content {
      background-color: #0f151d70;
      border-radius: 36px;
      position: relative;
      .modal-header {
        margin: 0 30px;
        border-bottom: 1px solid #474b50;
        padding-left: 0;
        padding-right: 0;
        .btn-close {
          color: var(--white);
          --bs-btn-close-bg: url("./Assets/CloseIcon.svg");
        }
      }
      .modal-body {
        margin: 0 30px;
        padding: 30px 0 60px;
      }
    }
  }
}
body.modal-open {
  #root {
    filter: blur(24px);
  }
}
.Toastify {
  position: fixed !important;
  z-index: 999999999999 !important; /* Adjust the z-index value as needed */
}

.Toastify__toast-container {
  z-index: 999999999999 !important;
}